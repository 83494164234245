@use 'custom_bootstrap_vars';
@use 'bootstrap/scss/bootstrap';
@import 'bootstrap-icons/font/bootstrap-icons.css';
// To import bootstrap-icons.scss instead
// @import 'bootstrap-icons/font/bootstrap-icons.scss';
// $bootstrap-icons-font-dir: 'bootstrap-icons/font/fonts';

///////////////
// Variables //
///////////////

// Alert colors

$white: #fff;
$mint-green: #d6e9c6;
$black: #000;
$pink: #f2dede;
$dark-pink: #eed3d7;
$red: #b94a48;

///////////
// Fonts //
///////////

// @font-face {
//   font-family: 'Montserrat';
//   src: url('../fonts/Montserrat-VariableFont_wght.ttf') format('truetype');
//   font-weight: 100 900;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'Montserrat';
//   src: url('../fonts/Montserrat-Italic-VariableFont_wght.ttf')
//     format('truetype');
//   font-weight: 100 900;
//   font-style: italic;
// }

//////////////
// Headings //
//////////////

// h1,
// h2,
// h3,
// h4,
// h5,
// h6 {
//   font-family: 'Montserrat', sans-serif !important;
//   color: #555;
// }

// h1::after,
// h2::after,
// h3::after {
//   content: '';
//   display: block;
//   margin-top: 0.5rem;
//   height: 1px;
//   width: 5rem;
//   background-color: red;
// }

h1 {
  margin-top: 24px !important;
  margin-top: 1.5rem !important;
  margin-bottom: 24px !important;
  margin-bottom: 1.5rem !important;
}

////////////
// Alerts //
////////////

// bootstrap alert CSS, translated to the django-standard levels of
// debug, info, success, warning, error

.alert-debug {
  background-color: $white;
  border-color: $mint-green;
  color: $black;
}

.alert-error {
  background-color: $pink;
  border-color: $dark-pink;
  color: $red;
}

////////////
// Tables //
////////////

.asc-alpha::after {
  content: '\f571';
  /* bi-sort-alpha-down */
  font-family: 'bootstrap-icons';
}

.desc-alpha::after {
  content: '\f572';
  /* bi-sort-alpha-up-alt */
  font-family: 'bootstrap-icons';
}

.asc-num::after {
  content: '\F577';
  /* bi-sort-numeric-down */
  font-family: 'bootstrap-icons';
}

.desc-num::after {
  content: '\f578';
  /* bi-sort-numeric-up-alt */
  font-family: 'bootstrap-icons';
}

.v-align-middle td {
  vertical-align: middle;
}

/////////////////////////
// Transaction details //
/////////////////////////

.transaction-details-wrapper {
  overflow-x: auto;
}

.transaction-details {
  font-size: 0.8em;
}

.transaction-details .json-key,
.transaction-details .json-value {
  padding-top: 0;
  padding-bottom: 0;
}

.transaction-details .json-key {
  font-weight: bold;
}

.transaction-details .json-value {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

///////////////////
// Visual effets //
///////////////////

.darken-50 {
  filter: brightness(50%);
}

.vertical-text {
  writing-mode: vertical-lr;
  text-orientation: upright;
}

@media (max-width: 767px) {
  .ffme-logo-wrapper {
    height: 140px;
  }

  .ffme-logo-rotate {
    transform: rotate(90deg) translate(-80px, 0px);
  }

  .sm-vertical-text {
    writing-mode: vertical-lr;
    text-orientation: upright;
  }
}

.yellow-glow {
  box-shadow:
    0 0 5px #ffc107,
    0 0 8px #ffc107,
    0 0 10px #ffc107;
  // color: #ff5107;
}

.rotate-180 {
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}

.icon-square {
  width: 3rem;
  height: 3rem;
  border-radius: 0.75rem;
}

.text-shadow {
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);
}

.no-margin-bottom p {
  margin-bottom: 0;
}

.offscreen {
  position: absolute;
  left: -9999px;
  top: -9999px;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

//////////
// Misc //
//////////

.logo-height {
  max-height: 80px;
}

.subscription-card .card-body {
  height: 14rem;
}

//////////////////
// Print styles //
//////////////////

@media print {
  .page-break {
    page-break-after: always;
  }
}
